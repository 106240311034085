@font-face {
  font-family: "Bazer";
  src: url("./assets/Bazer-K7vpp.ttf");
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.App{
  background-color: var(--black);
}
@media (min-width:1440px) {
  .HERO{
    padding: 30px 76px !important;
  }
  .innerHeroSection{
    padding: none !important;
  }
}
@media (max-width: 768px) {
  .custom-height{
    /* height:500px; */
    aspect-ratio: 16/9 !important;
    width: 100% !important;
    max-height: 50vw !important;
    /* max-height: calc(100vh- 40rem); */
  }
  .custom-height-without-bg{
    aspect-ratio: 16/9 !important;
    width: 100% !important;
    max-height: 70vw !important;
    background-image: linear-gradient(to right,var(--black),var(--primary-trans-2), var(--black));
  }
  .inner-Card{
    height: 80px !important;
  }
  .skillCard:hover > .inner-Card{
    transform: translateZ(0px) !important;
    transition: transform 0.3s;
    background-color: rgba(255, 147, 147, 0.637);
  }
  .skillCard:hover > .title{
    transition: transform 0.1s;
    transform: translateZ(0px) !important;
  }
  .skillCard:hover{
    scale:1 !important;
    border:none !important;
  }
  .custom-height-without-bg-projects{
    aspect-ratio: 16/9 !important;
    width: 100% !important;
    max-height: 65vh !important;
    height:fit-content !important ;
    background-image: linear-gradient(to right,var(--black),var(--primary-trans-2), var(--black));
  }
}
body{
  background-color: var(--black);
  /* overflow-x: hidden; */
  /* max-height: 100vh; */
  /* overflow-y: hidden; */
}
/* .custom{
  font-family: "Bazer";
}
.initialsContainer{
  animation: animate1 infinite 1000ms 0ms alternate;
}
@keyframes animate1 {
  0%{
   font-size: 136px;
   text-shadow: #000000 10px 10px 100px;
  }  
  100%{
    font-size:120px;
   text-shadow:  #CA4943 10px 10px 100px;
  }
}
::-webkit-scrollbar{
  width: 5px;
  height: 1vh;
}
::-webkit-scrollbar-thumb{
  background-color: #CA4943;
  border-radius: 10px;
  width: 5px;
}
::-webkit-scrollbar-track{
  width: 0px;
  background-color: #111111;
}
::-webkit-scrollbar-track-piece{
  width: 0px;
  background-color: #111111;
}
.text-stroke-1{
  -webkit-text-stroke: 1px #ffffff;
  color: #ffffff00;
} */

@import url('https://fonts.googleapis.com/css2?family=Big+Shoulders+Display:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Iceland&display=swap');

:root{
  --primary: #E84A4A;
  --faint-primary:#e84a4a57;
  --yellow:#7DFF68;
  --black:#000003;
  --grey:#242423;
  --primary-transparent: #e84a4ab3;
  --primary-trans-2:#e84a4a1a;
}
.inactive{
  background-color: var(--grey);
}
.yellow{
  color:var(--yellow);
}
.red{
  color:var(--primary);
}
.bg-primary-trans{
  background-color: var(--primary-transparent);
}
.bg-primary-2{
  background-color: var(--primary-trans-2);
}
.border-primary{
  border-color: var(--primary) !important;
}
.bg-primary{
  background-color: var(--primary) !important;
}
.hover-bg-primary:hover{
  background-color: var(--primary) !important;
}
.hover-bg-primary:hover img{
display: none;
}
.big-shoulders {
  font-family: "Big Shoulders Display", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
.iceland{
  font-family: "Iceland", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.box {
  position: relative;
  /* width: 200px;
  height: 200px;
  margin: 50px; */
}
.box-hover:hover{
  transition: 500ms;
  box-shadow: 0px 0px 500px 5px var(--primary);
  scale: 1.1;
}
.custom-height{
  height: calc(100vh - 8.6rem) !important;
  background-image: url(../src/assets/hero-large.webp);
  background-size: cover;
  background-position: center center;
}
.custom-height-without-bg{
  height: calc(100vh - 8.6rem) !important;
  background-image: linear-gradient(to right,var(--black),var(--primary-trans-2), var(--black));
}
.custom-height-without-bg-projects{
  height: calc(100vh - 8.6rem) !important;
  background-image: linear-gradient(to right,var(--black),var(--primary-trans-2), var(--black));
}
.custom-height:last-child
{
  background-image: none !important;
}
.box::before,
.box::after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid #E84A4A;
}

/* Top-left corner */
.box::before {
  top: -2px;
  left: -2px;
  border-right: none;
  border-bottom: none;
}

/* Bottom-right corner */
.box::after {
  bottom: -2px;
  right: -2px;
  border-left: none;
  border-top: none;
}

/* Additional corners can be added like this */

/* Top-right corner */
.box::before:nth-of-type(2) {
  top: -2px;
  right: -2px;
  border-left: none;
  border-bottom: none;
}
.HERO{
  perspective: 4500px;
  transform-style: preserve-3d;
  perspective-origin: center bottom;
}
/* Bottom-left corner */
.box::after:nth-of-type(2) {
  bottom: -2px;
  left: -2px;
  border-right: none;
  border-top: none;
}
.bg-custom-gradient{
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7), var(--faint-primary)) !important;
}
.projectCard{
box-shadow: 0px 0px 100px rgb(0, 0, 0) inset;
}
.projectCard .bg-image{
  height: 380px;
  aspect-ratio: 16/9 !important;
  z-index: -2;
  position: relative;
  top: 0;
  bottom: 0;
  filter: blur(50px);
}
.innerCard{
  /* position: relative; */
  /* z-index: 2; */
  display: block;
  height: 100%;
  margin-top: -380px;
  box-sizing: border-box;
  /* top: 0; */
}
.projectImage{
  aspect-ratio: 16/9;
}
.prev-slide{
  transform: rotateY(40deg) !important;
}
.next-slide{
  transform: rotateY(-40deg) !important;
}
.projectTitle{
  font-weight: 900 !important;
}
.actionsContainer button{
font-size: 12px !important;
}
.swipper-wrapper{
  perspective: 300px !important;
}
.experienceCard{
  background-image: url(../src//assets/placeholder2.png);
  background-size: cover;
  aspect-ratio: 75/46;
  background-color: rgba(245, 245, 220, 0);
}
.skillCard{
  perspective: 500px;
  transition: transform 0.3s;
}
.skillCard:hover{
  scale:1.2;
  border:none !important;
  z-index: 20;
}
.inner-Card{
  position: inherit;
  height: 120px;
  perspective: 1000px;
}
.skillCard:hover > .inner-Card{
  transform: translateZ(100px);
  transition: transform 0.3s;
  background-color: rgba(255, 147, 147, 0.637);
}
.skillCard:hover > .title{
  transition: transform 0.1s;
  transform: translateZ(500px);
}
::selection {
  background: var(--primary); /* Change background color to red */
  color: white;    /* Optionally, change text color to white */
}
::-webkit-selection {
  background: var(--primary); /* Change background color to red */
  color: white;    /* Optionally, change text color to white */
}

::-moz-selection {
  background: var(--primary); /* Change background color to red */
  color: white;    /* Optionally, change text color to white */
}
::-webkit-scrollbar{
  width: 3px !important;
  background-color: var(--black);
}
::-webkit-scrollbar-thumb{
  background-color: var(--primary);
  border-radius: 500px;
}